import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  VStack,
  Text,
  Heading,
  Container,
  SimpleGrid,
  Link,
  useBreakpointValue,
  Flex,
  Button,
  useColorModeValue,
  Badge,
  Spinner,
  Center,
  IconButton,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getKeywords, likeKeyword } from "../../api";
import { IKeyword } from "../../types";
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { IoFilterCircleOutline } from "react-icons/io5";
import useUser from '../../lib/useUser';

type SortOption = 'latest' | 'popular';

const ITEMS_PER_PAGE = 12;
const MIN_LOADING_TIME = 3000;

export const CategoryDetail: React.FC = () => {
  const { category } = useParams<{ category: string }>();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialPage = parseInt(searchParams.get('page') || '1', 10);
  const initialSort = (searchParams.get('sort') as SortOption) || 'latest';
  const [page, setPage] = useState(initialPage);
  const [sortBy, setSortBy] = useState<SortOption>(initialSort);
  const [isLoading, setIsLoading] = useState(true);
  const queryClient = useQueryClient();
  const toast = useToast();
  const { isLoggedIn } = useUser();

  const handleKeywordClick = (keywordId: string) => {
    // 현재 페이지와 정렬 정보를 state로 전달
    navigate(`/keywords/${keywordId}`, {
      state: {
        prevPage: page,
        prevSort: sortBy
      }
    });
  };

  const handleLikeClick = (e: React.MouseEvent, keywordId: string) => {
    e.stopPropagation();  // 이벤트 버블링 방지
    if (isLoggedIn) {
      likeMutation.mutate(keywordId);
    } else {
      showLoginRequiredToast();
    }
  };

  // URL과 상태 동기화
  useEffect(() => {
    const pageParam = searchParams.get('page');
    const sortParam = searchParams.get('sort') as SortOption;
    const currentPage = parseInt(pageParam || '1', 10);

    if (currentPage !== page) {
      setPage(currentPage);
    }
    if (sortParam && sortParam !== sortBy) {
      setSortBy(sortParam);
    }
  }, [searchParams]);

  // 페이지 또는 정렬 변경 시 URL 업데이트
  const updateSearchParams = (newPage: number, newSort: SortOption) => {
    setSearchParams({
      page: newPage.toString(),
      sort: newSort
    });
    setPage(newPage);
    setSortBy(newSort);
  };

  const handleSortChange = (newSort: SortOption) => {
    updateSearchParams(1, newSort); // 정렬 변경 시 1페이지로 이동
  };

  const { data: keywords, error, isFetching } = useQuery<IKeyword[]>(
    ["keywords"],
    getKeywords,
    {
      staleTime: 60000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (isFetching) {
      setIsLoading(true);
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, MIN_LOADING_TIME);
      return () => clearTimeout(timer);
    } else {
      setIsLoading(false);
    }
  }, [isFetching]);

  const containerWidth = useBreakpointValue({ base: "90%", md: "80%", lg: "70%" });
  const columns = useBreakpointValue({ base: 1, md: 2, lg: 3 });

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const bgSelected = useColorModeValue('gray.100', 'gray.600');

  const likeMutation = useMutation(likeKeyword, {
    onMutate: async (keywordId) => {
      await queryClient.cancelQueries(["keywords"]);
      const previousKeywords = queryClient.getQueryData<IKeyword[]>(["keywords"]);
      if (previousKeywords) {
        const updatedKeywords = previousKeywords.map(kw =>
          kw.pk.toString() === keywordId ? { ...kw, is_liked: !kw.is_liked } : kw
        );
        queryClient.setQueryData(["keywords"], updatedKeywords);
      }
      return { previousKeywords };
    },
    onError: (err, _, context) => {
      if (context?.previousKeywords) {
        queryClient.setQueryData(["keywords"], context.previousKeywords);
      }
      toast({
        title: "오류가 발생했습니다",
        description: "잠시 후 다시 시도해주세요",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom",
        variant: "subtle",
      });
    },
    onSuccess: (_, keywordId) => {
      const keywords = queryClient.getQueryData<IKeyword[]>(["keywords"]);
      const keyword = keywords?.find(kw => kw.pk.toString() === keywordId);
      toast({
        title: keyword?.is_liked ? "도서관에 저장되었습니다." : "저장을 취소했습니다",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "bottom",
        variant: "subtle",
        icon: keyword?.is_liked ? <FaHeart /> : <FaRegHeart />,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(["keywords"]);
    },
  });

  const showLoginRequiredToast = () => {
    toast({
      title: "로그인이 필요합니다",
      description: "도서관에 저장할 수 있습니다.",
      status: "warning",
      duration: 4000,
      isClosable: true,
      position: "bottom",
    });
  };

  if (isLoading) {
    return (
      <Center height="100vh">
        <Spinner size="xl" />
        <Text ml={4}>Loading...</Text>
      </Center>
    );
  }

  if (error) return <Center height="100vh"><Text>단어를 불러오는 중 오류가 발생했습니다.</Text></Center>;
  if (!keywords || keywords.length === 0) return <Center height="100vh"><Text>단어가 없습니다.</Text></Center>;

  // 카테고리 필터링
  let filteredKeywords = keywords.filter(keyword =>
    typeof keyword.category === 'object' && keyword.category.name === category
  );

  // 정렬 로직
  const sortedKeywords = [...filteredKeywords].sort((a, b) => {
    if (sortBy === 'popular') {
      // 좋아요 수로 정렬 (likes_count가 IKeyword 타입에 있다고 가정)
      return (b.likes_count || 0) - (a.likes_count || 0);
    } else {
      // 최신순 정렬 (created_at이 IKeyword 타입에 있다고 가정)
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    }
  });

  if (sortedKeywords.length === 0) return <Center height="100vh"><Text>이 카테고리에 단어가 없습니다.</Text></Center>;

  const totalPages = Math.ceil(sortedKeywords.length / ITEMS_PER_PAGE);
  const paginatedKeywords = sortedKeywords.slice(
    (page - 1) * ITEMS_PER_PAGE,
    page * ITEMS_PER_PAGE
  );

  return (
    <Container maxW={containerWidth} py={8}>
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        <Heading>{category}</Heading>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="정렬 옵션"
            icon={<IoFilterCircleOutline size="22px" />} 
            variant="ghost"
            _hover={{ bg: 'transparent' }}
            _active={{ bg: 'transparent' }}
            _focus={{ bg: 'transparent' }}  // 포커스 시에도 배경 없음
            bg="transparent"                // 기본 배경도 투명하게
          />
          <MenuList minW="120px">
            <MenuItem
              onClick={() => handleSortChange('latest')}
              fontWeight={sortBy === 'latest' ? 'bold' : 'normal'}
              bg={sortBy === 'latest' ? bgSelected : 'transparent'}
              _hover={{ bg: bgSelected }}
              fontSize="sm"
            >
              최신순
            </MenuItem>
            <MenuItem
              onClick={() => handleSortChange('popular')}
              fontWeight={sortBy === 'popular' ? 'bold' : 'normal'}
              bg={sortBy === 'popular' ? bgSelected : 'transparent'}
              _hover={{ bg: bgSelected }}
              fontSize="sm"
            >
              인기순
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>

      <SimpleGrid columns={columns} spacing={6}>
        {paginatedKeywords.map((keyword) => (
          <Box
            key={keyword.pk}
            p={5}
            shadow="md"
            borderWidth="1px"
            borderRadius="lg"
            bg={bgColor}
            borderColor={borderColor}
            _hover={{ shadow: 'lg', transform: 'translateY(-2px)' }}
            transition="all 0.2s"
            onClick={() => handleKeywordClick(keyword.pk.toString())}
            cursor="pointer"
          >
            <Flex justifyContent="space-between" alignItems="center" mb={2}>
              <Heading fontSize="xl">
                {keyword.english_term}
              </Heading>
              <Flex alignItems="center">
                <IconButton
                  aria-label={keyword.is_liked ? "Unlike" : "Like"}
                  icon={keyword.is_liked ? <FaHeart /> : <FaRegHeart />}
                  colorScheme={keyword.is_liked ? "red" : "gray"}
                  onClick={(e) => handleLikeClick(e, keyword.pk.toString())}
                  isLoading={likeMutation.isLoading}
                  size="sm"
                  variant="ghost"
                />
              </Flex>
            </Flex>
            <Text fontSize="md" fontWeight="bold" color="gray.500" mb={2}>
              {keyword.korean_term}
            </Text>
            <Text fontSize="sm" noOfLines={2}>
              {keyword.description}
            </Text>
          </Box>
        ))}
      </SimpleGrid>

      <Flex justifyContent="center" mt={6}>
        <Button
          onClick={() => updateSearchParams(Math.max(1, page - 1), sortBy)}
          isDisabled={page === 1}
          mr={2}
        >
          이전
        </Button>
        <Text alignSelf="center" mx={2}>
          {page} / {totalPages}
        </Text>
        <Button
          onClick={() => updateSearchParams(Math.min(totalPages, page + 1), sortBy)}
          isDisabled={page === totalPages}
          ml={2}
        >
          다음
        </Button>
      </Flex>
    </Container>
  );
};