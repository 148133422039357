import React, { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  Tag,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  Tooltip,
  useBreakpointValue
} from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import { ChevronRightIcon } from '@chakra-ui/icons';

interface CategoryProps {
  categories: string[];
}

export const Category: React.FC<CategoryProps> = ({ categories }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // 반응형으로 visible 카테고리 수 조정
  const visibleCategoryCount = useBreakpointValue({
    base: 3,  // 모바일
    sm: 4,    // 작은 태블릿
    md: 5,    // 태블릿
    lg: 7,    // 데스크톱
  }) ?? 3;    // 기본값

  // Color mode values
  const bgColor = useColorModeValue("gray.100", "gray.700");
  const hoverBgColor = useColorModeValue("gray.100", "gray.600");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const tagColor = useColorModeValue("blue.300", "blue.300");
  const modalBg = useColorModeValue("white", "gray.800");
  const btnBg = useColorModeValue("gray.100", "gray.700");

  // Visible categories based on screen size
  const visibleCategories = categories.slice(0, visibleCategoryCount);
  const hasMoreCategories = categories.length > visibleCategoryCount;

  const handleCategoryClick = (category: string) => {
    navigate(`/category/${category}`);
    setIsModalOpen(false);
  };

  // Modal columns based on screen size
  const modalColumns = useBreakpointValue({ base: 2, sm: 2, md: 3, lg: 4 }) ?? 2;

  return (
    <Box position="relative" width="100%" pt={2}>
      <Flex
        overflowX="auto"
        alignItems="center"
        width="100%"
        pb={2}
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          scrollbarWidth: 'none',
          msOverflowStyle: 'none'
        }}
      >
        {/* Main categories */}
        <Flex gap={2} flex="1">
          {visibleCategories.map((category, index) => (
            <Box key={index} py={1}>
              <Tag
                px={3}
                py={2}
                bg={bgColor}
                color={textColor}
                borderRadius="full"
                cursor="pointer"
                onClick={() => handleCategoryClick(category)}
                _hover={{
                  bg: hoverBgColor,
                  transform: "translateY(-2px)",
                  boxShadow: "sm"
                }}
                transition="all 0.2s"
                fontSize={{ base: "sm", md: "md" }}
                fontWeight="medium"
                flexShrink={0}
                position="relative"
                zIndex={1}
              >
                <Text>{category}</Text>
                <Box
                  as="span"
                  ml={2}
                  bg={tagColor}
                  w={1.5}
                  h={1.5}
                  borderRadius="full"
                />
              </Tag>
            </Box>
          ))}

          {/* More categories button */}
          {hasMoreCategories && (
            <Box py={1}>
              <Tooltip>
                <IconButton
                  aria-label="View all categories"
                  icon={<ChevronRightIcon boxSize={{ base: 4, md: 5 }} />}
                  onClick={() => setIsModalOpen(true)}
                  variant="solid"
                  bg={btnBg}
                  size={{ base: "sm", md: "md" }}
                  borderRadius="full"
                  ml={2}
                  boxShadow="sm"
                  _hover={{
                    transform: "translateY(-2px)",
                    boxShadow: "md"
                  }}
                  transition="all 0.2s"
                  flexShrink={0}
                />
              </Tooltip>
            </Box>
          )}
        </Flex>
      </Flex>

      {/* Modal for all categories */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        size={{ base: "full", md: "2xl" }}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent bg={modalBg}>
          <ModalHeader fontSize={{ base: "lg", md: "xl" }}>
            전체 카테고리
          </ModalHeader>
          <ModalCloseButton
            _hover={{ bg: 'transparent' }}  // hover 시 배경색 없음
            _active={{ bg: 'transparent' }} // 클릭 시에도 배경색 없음
          />
          <ModalBody pb={6}>
            <SimpleGrid columns={modalColumns} spacing={4}>
              {categories.map((category, index) => (
                <Tag
                  key={index}
                  px={4}
                  py={3}
                  bg={bgColor}
                  color={textColor}
                  borderRadius="full"
                  cursor="pointer"
                  onClick={() => handleCategoryClick(category)}
                  _hover={{ bg: hoverBgColor }}
                  transition="all 0.2s"
                  fontSize={{ base: "sm", md: "md" }}
                  fontWeight="medium"
                  justifyContent="center"
                >
                  <Text>{category}</Text>
                  <Box
                    as="span"
                    ml={2}
                    bg={tagColor}
                    w={1.5}
                    h={1.5}
                    borderRadius="full"
                  />
                </Tag>
              ))}
            </SimpleGrid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};